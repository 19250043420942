import { boot } from 'quasar/wrappers';
import i18n from 'src/i18n';

export default boot(({ app, ssrContext }) => {
  const host = ssrContext ? ssrContext.req.hostname : window.location.host;
  const match = host.match(/^(en|zh)\./);
  let lang = match
    ? match[0].replace(/\.$/, '')
    : process.env.DEFAULT_LANGUAGE ?? 'zh-Hans';
  if (lang === 'zh') lang = 'zh-Hans';
  if (lang === 'en') lang = 'en-US';
  app.use(i18n, { lang });
});
