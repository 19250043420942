import { boot } from 'quasar/wrappers';
import axios, { AxiosInstance } from 'axios';
import { Notify } from 'quasar';
import { i18n } from 'src/i18n/index';

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $axios: AxiosInstance;
    $api: AxiosInstance;
  }
}

try {
  // 解决node.js调用自签名证书报错的问题
  process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';
} catch {
  // nothing
}

// Be careful when using SSR for cross-request state pollution
// due to creating a Singleton instance here;
// If any client changes this (global) instance, it might be a
// good idea to move this instance creation inside of the
// "export default () => {}" function below (which runs individually
// for each client)

const api = axios.create({
  baseURL: '/',
  xsrfCookieName: 'XSRF-TOKEN',
  xsrfHeaderName: 'RequestVerificationToken',
  headers: {},
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const { response } = error;

    let errMessage = '';
    if (response?.data) {
      const data = response?.data as any;
      errMessage =
        data.error_description ?? data.error?.details ?? data.error?.message;
    } else {
      errMessage = `发生${response?.status}错误，${
        response?.statusText ?? '请求出错，请稍候重试。'
      }`;
    }
    if (errMessage) {
      Notify.create({
        type: 'negative',
        position: 'top',
        html: true,
        message: errMessage
          .replace(/[\r\n]/g, '<br/>')
          .replace(/<br\/><br\/>/g, '<br/>'),
      });
    }

    return Promise.reject(error);
  }
);

export default boot(({ app, ssrContext }) => {
  api.interceptors.request.clear();
  // for use inside Vue files (Options API) through this.$axios and this.$api
  api.interceptors.request.use((config) => {
    config.headers['Accept-Language'] = i18n.global.locale.value;
    if (ssrContext) {
      config.baseURL = 'http://localhost:' + (process.env.PORT || 3000);
    } else {
      config.baseURL = '/';
    }
    // config.headers['__tenant'] = process.env.TENANT_NAME;
    return config;
  });

  app.config.globalProperties.$axios = axios;
  // ^ ^ ^ this will allow you to use this.$axios (for Vue Options API form)
  //       so you won't necessarily have to import axios in each vue file

  app.config.globalProperties.$api = api;
  // ^ ^ ^ this will allow you to use this.$api (for Vue Options API form)
  //       so you can easily perform requests against your app's API
});

export { api };
