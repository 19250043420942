import { App } from 'vue';
import { createI18n } from 'vue-i18n';
import zh_Hans from './modules/zh-Hans.json';
import en_US from './modules/en-US.json';

const messages = {
  'zh-Hans': zh_Hans,
  'en-US': en_US,
};

export const i18n = createI18n({
  legacy: false,
  globalInjection: true, // 全局生效$t
  locale: 'zh-Hans',
  fallbackLocale: 'zh-Hans',
  messages: messages,
});

export default {
  install(app: App, options: { lang: string }) {
    i18n.global.locale.value = options.lang as any;
    app.use(i18n);
  },
};
